import React, { Fragment } from "react";

const CheckboxField = ({ text, formik, name, info }) => {

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    return (
        <div className="form-group">

            <div className="form-group__checkbox form-check">

                <input
                    className={`form-check-input ${isInvalidClass()}`}
                    name={name}
                    type="checkbox"
                    id={name}
                    checked={formik.getFieldProps(name).value}
                    {...formik.getFieldProps(name)}
                />

                <label className="form-group__label form-check-label">
                    {Array.isArray(text) ?
                        text.map(( item, index ) => {
                            return (
                                <Fragment key={index}>{item}</Fragment>
                            )
                        })
                    : text}
                </label>

                {formik.touched[name] && formik.errors[name] ? (
                    <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
                ) : null}

                {info && <small className="form-group__info form-text">{info}</small>}

            </div>

        </div>
    );
};

export default CheckboxField;
