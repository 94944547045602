import React, { useEffect, useRef } from "react";
import PinInput from "react-pin-input";

const OtpField = ({ name, label, formik, disabled }) => {
    let otpInputRef = useRef();

    const onChange = (values) => {
        formik.setFieldValue("otpInput", values);
    };

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    useEffect(() => {
        if (formik.values[name] === "") {
            otpInputRef.clear();
        }
    }, [formik.values, name]);

    return (
        <div className="form-group">
            <label className="form-group__label" htmlFor={name}>
                {label}
            </label>

            <PinInput
                ref={(ref) => (otpInputRef = ref)}
                length={6}
                initialValue=""
                type="numeric"
                inputMode="number"
                className={`form-group__form-control form-control ${isInvalidClass()}`}
                id={name}
                disabled={disabled}
                onChange={onChange}
            />

            {formik.touched[name] && formik.errors[name] ? (
                <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
            ) : null}
        </div>
    );
};

export default OtpField;
