import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import { durationInSecondsToMmSs } from "../../app.utils";
import OtpField from "../form/otp-field";
import crossIcon from "../../assets/images/close_gray.svg";

const AppSmsModal = ({ closeModal, requestOtp, ...props }) => {
    const [disabled, setDisabled] = useState(false);
    const otpExpirationTime = 300;
    const [time, setTime] = useState(otpExpirationTime);
    const intl = useIntl();
    const messages = intl.messages;
    const error = messages["smsModal.required"];

    const formik = useFormik({
        initialValues: {
            otpInput: "",
        },
        validationSchema: Yup.object({
            otpInput: Yup.number(error)
                .test("length", error, (otp) => otp && otp.toString().length === 6)
                .required(error),
        }),
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            props.onSubmit(values, () => {
                action.setSubmitting(false);
            });
        },
    });

    const resetOtp = () => {
        requestOtp();
        setTime(otpExpirationTime);
        formik.resetForm();
        setDisabled(false);
    };

    useEffect(() => {
        if (time === null) {
            setTime(otpExpirationTime);
        }
        if (time === 0) {
            setDisabled(true);
        }
        if (time >= 0) {
            const timer = setTimeout(() => {
                setTime(Math.max(0, time - 1));
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            setTime(0);
        }
    }, [time]);

    return (
        <Fragment>
            <div className="app-sms-modal">
                <div className="app-sms-modal__body">
                    <button className="app-sms-modal__close-btn" type="button" onClick={closeModal}>
                        <img className="app-sms-modal__close-icon img-fluid" src={crossIcon} alt="close" />
                    </button>

                    <p className="app-sms-modal__header text-bold">
                        <FormattedMessage id="smsModal.title" />
                    </p>

                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <div className="app-sms-modal__content">
                            <p className="app-sms-modal__info">
                                <FormattedMessage id="smsModal.instruction" />
                            </p>

                            <div className="app-sms-modal__otp">
                                <OtpField name="otpInput" formik={formik} disabled={disabled} />
                            </div>

                            <p className="app-sms-modal__timeout">
                                {time > 0 ? (
                                    <FormattedMessage id="smsModal.countDown.start" values={{ time: durationInSecondsToMmSs(time) }} />
                                ) : (
                                    <FormattedMessage id="smsModal.countDown.end" />
                                )}
                            </p>
                        </div>

                        <div className="app-sms-modal__footer">
                            <div className="btn-container">
                                {time > 0 ? (
                                    <button
                                        className="btn btn-lg btn-primary"
                                        type="submit"
                                        disabled={formik.isSubmitting || disabled}>
                                        <FormattedMessage id="smsModal.cta.submit" />
                                    </button>
                                ) : (
                                    <button className="btn btn-lg btn-primary" type="button" onClick={resetOtp}>
                                        <FormattedMessage id="smsModal.cta.requestOtp" />
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>

                <div className="app-sms-modal__backdrop"></div>
            </div>
        </Fragment>
    );
};

export default AppSmsModal;
