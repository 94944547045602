import React, { Fragment } from "react";

const SelectField = ({ name, label, type, formik, placeholder, info, options }) => {

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    return (
        <div className="form-group">

            <label className="form-group__label" htmlFor={name}>
                {label}
            </label>

            <select
                className={`form-group__select custom-select ${isInvalidClass()}`}
                type={type !== null ? type : "text"}
                id={name}
                {...formik.getFieldProps(name)}
            >
                <Fragment>
                    {placeholder ? (
                        <option value="" key="placeholder">
                            {placeholder}
                        </option>
                    ) : null}

                    {options.map((option, index) => (
                        <option
                            key={index}
                            value={option.value}
                            label={option.label}
                        >{option.label}</option>
                    ))}
                </Fragment>
            </select>

            {formik.touched[name] && formik.errors[name] ? (
                <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
            ) : null}

            {info && <small className="form-group__info form-text">{info}</small>}

        </div>
    );
};

export default SelectField;
