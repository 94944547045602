import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class TimestampStore {
    tncAgreed = "";
    tncAgreedAllAccount = "";
    tnc06 = "";
    tnc07 = "";
    tnc08 = "";
    tncAgreedAllReviewOrder = "";

    updateTncAgreedTimestamp = (tncAgreed) => {
        this.tncAgreed = tncAgreed;
    };
    
    updateTncAgreedAllAccountTimestamp = (tncAgreedAllAccount) => {
        this.tncAgreedAllAccount = tncAgreedAllAccount;
    };
    
    updateTnc06Timestamp = (tnc06) => {
        this.tnc06 = tnc06;
    };

    updateTnc07Timestamp = (tnc07) => {
        this.tnc07 = tnc07;
    };
    
    updateTnc08Timestamp = (tnc08) => {
        this.tnc08 = tnc08;
    };

    updateTncAgreedAllReviewOrderTimestamp = (tncAgreedAllReviewOrder) => {
        this.tncAgreedAllReviewOrder = tncAgreedAllReviewOrder;
    };
}

decorate(TimestampStore, {
    tncAgreed: [persist, observable],
    tncAgreedAllAccount: [persist, observable],
    tnc06: [persist, observable],
    tnc07: [persist, observable],
    tnc08: [persist, observable],
    tncAgreedAllReviewOrder: [persist, observable],
    updateTimestamp: action,
});

var timestampStore = new TimestampStore();
export default timestampStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("timestampStore", timestampStore);
}
